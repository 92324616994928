import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { MenuService } from '../menu/menu.service';
import { Menu } from '../menu/menu.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService]
})
export class SidebarComponent implements OnInit {
  public settings: Settings;
  public menuItems: Array<any>;
  constructor(public appSettings: AppSettings,
     public menuService: MenuService, private router: Router) {
    this.settings = this.appSettings.settings;
    this.menuItems = this.menuService.getVerticalMenuItems();
    if (localStorage.getItem('logged_in_as') == 'school') {
    this.menuItems = [
      new Menu(1, 'Dashboards', null, null, 'tachometer', null, true, 0),
      new Menu(11, 'KPSA Dashboard', '/', null, 'tachometer', null, false, 1),
      new Menu(9000, 'Manage my school', '/kpsa/school-info' , null , 'cogs', null, false, 0),
      new Menu(5000, 'Manage Classes', '/kpsa/classes' , null , 'th-list', null, false, 0),
      new Menu(5001, 'School Performance', '/kpsa/school-performance' , null , 'check', null, false, 0),
      new Menu(5003, 'Manage Subjects', '/kpsa/list-subjects' , null , 'book', null, false, 0),
      new Menu(5004, 'Manage Teachers', '/kpsa/list-teachers' , null , 'users', null, false, 0),
      new Menu(5005, 'School Contacts', '/kpsa/school-contacts' , null , 'phone', null, false, 0),
      new Menu(5006, 'School Licenses', '/kpsa/school-licenses' , null , 'certificate', null, false, 0),
      new Menu(5002, 'Invoices', '/kpsa/school-invoices' , null , 'money', null, false, 0),
      new Menu(50021, 'Transactions', '/kpsa/school-transactions' , null , 'money', null, false, 0),
    ];
   // this.router.navigateByUrl('kpsa/school-info');
    } else {
  
    }
  }

  ngOnInit() {
    if (sessionStorage["userMenuItems"]) {
      let ids = JSON.parse(sessionStorage.getItem("userMenuItems"));
      let newArr = [];
      ids.forEach(id => {
        let newMenuItem = this.menuItems.filter(mail => mail.id == id);
        newArr.push(newMenuItem[0]);
      });
      this.menuItems = newArr;
    }
  }

  public closeSubMenus() {
    let menu = document.querySelector("#menu0");
    for (let i = 0; i < menu.children.length; i++) {
      let child = menu.children[i].children[1];
      if (child) {
        if (child.classList.contains('show')) {
          child.classList.remove('show');
          menu.children[i].children[0].classList.add('collapsed');
        }
      }
    }
  }


}
