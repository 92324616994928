import { Menu } from './menu.model';

export const verticalMenuItems = [
  new Menu(1, 'Dashboards', null, null, 'tachometer', null, true, 0),
  new Menu(11, 'KPSA Dashboard', '/', null, 'tachometer', null, false, 1),
 // new Menu(12, 'Calendar', '/calendar', null, 'calendar', null, false, 0),

/**  Schools Management (KPSA) */
new Menu(1000, 'Schools Management', null , null , 'university', null, true, 0),
new Menu(10001, 'Registered Schools', '/kpsa/schools', null, 'th-list', null, false, 1000),

  new Menu(6000, 'Users Management', null, null, 'users', null, true, 0),
  new Menu(6001, 'Users', '/users/list', null, 'th-list', null, false, 6000),

  new Menu(2000, 'System Configurations', null, null, 'cogs', null, true, 0),
  new Menu(2001, 'School Curriculums', '/kpsa/configs/curriculumns', null, 'th-list', null, false, 2000),
  new Menu(2002, 'School Categories', '/kpsa/configs/school-categories', null, 'th-list', null, false, 2000),
  new Menu(2004, 'Manage Fees', '/kpsa/configs/fees', null, 'money', null, false, 2000),
  new Menu(2005, 'Manage Cost Bands', '/kpsa/configs/cost-bands', null, 'money', null, false, 2000),

  new Menu(2003, 'RBAC', null, null, 'users', null, true, 2000),
  new Menu(20032, 'Profiles', '/configs/rbac/profiles', null, 'th-list', null, false, 2003),
  new Menu(20033, 'Roles', '/configs/rbac/roles', null, 'th-list', null, false, 2003)
];

export const horizontalMenuItems = [
  new Menu(1, 'Dashboard', '/', null, 'tachometer', null, false, 0),
];
